import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js"
import { container, whiteColor } from "assets/jss/material-kit-pro-react.js"

const headerSection = theme => ({
  ...headerLinksStyle(theme),
  container: {
    ...container,
    "& h1, & h5": {
      color: whiteColor,
      margin: "0px",
    },
    "& h1": {
      fontWeight: "500",
    },
  },
  card: {
    backgroundColor: "rgb(0,0,0,0.6)",
    padding: "30px 50px",
    bottom: "20px",
    marginTop: "230px"
  },
  description: {
    fontSize: "1.5rem",
  },
  hightlight: {
    fontSize: "3rem",
  }
})

export default headerSection
