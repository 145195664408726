import React from "react"
import PropTypes from "prop-types"
// Gatsby components
import { handleLinkClick } from "gatsby-plugin-anchor-links/utils"
import { getSrc } from "gatsby-plugin-image"
// @material-ui/core components
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import { Container } from "@material-ui/core"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "components/Header/Header.js"
import Card from "components/appComponents/Card/Card"
import Parallax from "components/appComponents/Parallax/Parallax"
import Button from "components/CustomButtons/Button"
import ContactDialog from "components/appComponents/ContactDialog/ContactDialog"
import { FaLinkedin } from "react-icons/fa"
// style
import headerStyle from "assets/jss/app/views/headerSectionStyle"

const HeaderSection = withStyles(headerStyle)((props) => {
  const { classes, title, description, image, ...rest } = props

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const openDialog = () => setDialogIsOpen(true)
  const closeDialog = () => setDialogIsOpen(false)

  return (
    <div>
      <Header
        fixed
        color="darkGradient"
        routes={[]}
        brand="UXCITY"
        rightLinks={
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                onClick={(e) => handleLinkClick("/#services", e)}
              >
                What we do?
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                onClick={(e) => handleLinkClick("/#team", e)}
              >
                Team
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                onClick={(e) => handleLinkClick("/#projects", e)}
              >
                Our work
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button color="transparent" onClick={openDialog}>
                Contact Us
              </Button>
              <ContactDialog open={dialogIsOpen} onClose={closeDialog} />
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                justIcon
                color="transparent"
                target="_blank"
                href="https://www.linkedin.com/company/uxcity/"
              >
                <FaLinkedin />
              </Button>
            </ListItem>
          </List>
        }
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={getSrc(image)}>
        <Container className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes.card}>
                <h1 className={classes.hightlight}>{title}</h1>
                <h5 className={classes.description}>{description}</h5>
              </Card>
            </GridItem>
          </GridContainer>
        </Container>
      </Parallax>
    </div>
  )
})

export default HeaderSection

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

HeaderSection.defaultProps = {
  title: null,
  description: null,
  image: null,
}
